<template>
  <v-row class="justfy-center align-center" justify="center">
    <v-col cols="5">
      <v-autocomplete
        v-model="form.consultores"
        dense
        autocomplete="autocomplete_off_hack_xfr4!k"
        item-text="user.name"
        item-value="CodConsultorTecnico"
        label="Consultores"
        validate-on-blur
        :loading="loading.consultor"
        :disabled="loading.consultor"
        :items="consultores"
        multiple
        clearable
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index < CHIP_LIMIT" outlined small class="caption">
            <span v-if="index < CHIP_LIMIT">
              {{ item.user.name }}
            </span>
          </v-chip>
          <span v-if="index === CHIP_LIMIT" class="grey--text caption">
            (+{{ form.consultores.length - CHIP_LIMIT }}
            outros)
          </span>
        </template>
      </v-autocomplete>
    </v-col>

    <v-col cols="2">
      <v-btn color="green darken-1 white--text rounded-lg" @click="filter">
        <span>Filtrar</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FilterDialog',

  emits: ['filter'],

  props: {
    consultores: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: {
        consultor: false,
      },

      form: {
        consultores: [],
      },

      CHIP_LIMIT: 4,
    }
  },

  methods: {
    filter() {
      this.$emit('filter', this.form.consultores)
    },
  },
}
</script>
