import axios from '../../../api/axios'
import { _orderByName } from '../../../utils/formatter'

export default {
  async index(form) {
    const response = await axios.post('/consultant-dashboard', form)

    const { data } = response.data

    const normalized = data.consultores.map(consultant => {
      return {
        ...consultant,
        name: consultant?.user?.name || consultant?.pessoa?.pessoafisica?.Nome,
      }
    })

    const result = _orderByName({
      data: normalized,
      key: 'name',
    })

    return {
      consultores: result,
      agroindustryLogos: data.agroindustryLogos,
    }
  },
}
