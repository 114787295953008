<template>
  <div>
    <v-dialog v-model="dialog" max-width="900" hide-overlay>
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex">
          <v-btn class="mx-2 transparent" fab small v-bind="attrs" v-on="on">
            <v-icon> mdi-eye-outline </v-icon>
          </v-btn>
        </div>
      </template>
      <v-sheet
        class="gradient-sheet align-center justify-center text-center"
        height="100%"
      >
        <h3 class="text-h3">Empresas vinculadas</h3>
        <v-row class="fill-height">
          <v-col cols="10" md="6" class="d-flex">
            <div>
              <img
                v-if="user.avatar"
                :src="user.avatar"
                :alt="user.name"
                width="400px"
                height="400px"
              />
              <v-icon
                v-else
                color="white"
                size="400px"
                class="mdi mdi-account"
              ></v-icon>
              <div class="info-card-person">
                <p class="text-h3">
                  {{ user.name }},
                  {{ user.year }}
                  Anos - {{ user?.Formacao || 'Não informado' }}
                </p>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="6" class="text-center">
            <h4 class="text-h4">
              Empresas vinculadas {{ agroindustrias.length }}
            </h4>

            <v-row cols="4">
              <v-tabs
                v-model="tab"
                centered
                background-color="transparent"
                icons-and-text
              >
                <v-tab v-for="(agro, i) in tabs" :key="i">
                  Pág {{ i + 1 }} <v-icon>mdi-factory</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items
                v-model="tab"
                background-color="transparent"
                transparent
                class="d-flex"
              >
                <v-tab-item
                  v-for="(item, i) in tabs"
                  :key="i"
                  background-color="transparent"
                >
                  <v-col
                    v-for="agroindustria in item"
                    :key="agroindustria.CodFazendaAgroindustria"
                    cols="9"
                    class=""
                  >
                    <div class="d-flex justify-center text-center align-center">
                      <div class="mr-2">
                        <img
                          v-if="agroindustria.avatar"
                          :src="agroindustria.avatar"
                          :alt="
                            agroindustria.pessoajuridica_agroindustria
                              .NomeFantasia
                          "
                          width="60px"
                          height="60px"
                        />
                        <v-icon
                          v-else
                          color="white"
                          size="60px"
                          class="mdi mdi-factory"
                        ></v-icon>

                        <p class="text-h5">
                          {{
                            agroindustria.pessoajuridica_agroindustria
                              .NomeFantasia
                          }}
                        </p>
                      </div>
                      <div>
                        <h4 class="text-h4">
                          {{ agroindustria.fazendas.length }} Produtores
                        </h4>
                      </div>
                    </div>
                  </v-col>
                </v-tab-item>
              </v-tabs-items>
            </v-row>
          </v-col>
        </v-row>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { sliceIntoChunks } from '../../../utils/extra'

export default {
  name: 'LinkedCompanies',

  props: {
    user: {
      type: Object,
      required: true,
    },

    agroindustryLogos: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      tab: 0,
      tabs: [],

      agroindustrias: [],
    }
  },

  created() {
    this.index()

    this.tabs = sliceIntoChunks(this.agroindustrias, 3)
  },

  methods: {
    index() {
      const agroindustrias = this.user.fazendas.map(fazenda => {
        return fazenda?.agroindustria_ativa
      })

      let agroindustriasFiltered = agroindustrias.filter(
        agroindustria => agroindustria !== null,
      )

      agroindustriasFiltered = agroindustriasFiltered.filter(
        (agroindustria, index, self) =>
          index ===
          self.findIndex(
            t => t.CodAgroindustria === agroindustria.CodAgroindustria,
          ),
      )

      agroindustriasFiltered = agroindustriasFiltered.map(agroindustria => {
        return {
          ...agroindustria,
          fazendas: this.user.fazendas.filter(fazenda => {
            return (
              fazenda?.agroindustria_ativa?.CodAgroindustria ===
              agroindustria?.CodAgroindustria
            )
          }),
          avatar: Object.values(this.agroindustryLogos).find(
            logo => logo.CodAgroindustria === agroindustria.CodAgroindustria,
          )?.logotipo,
        }
      })

      this.agroindustrias = agroindustriasFiltered
    },
  },
}
</script>

<style scoped>
.gradient-sheet {
  background: linear-gradient(to bottom right, #0d133d, #243189);
  padding: 20px;
  border-radius: 8px;
}
.info-card-person {
  border: 5px solid #32bd50;
  border-radius: 1rem;
  padding: 0.4rem;
  max-width: 300px;
  bottom: 15px;
  margin-left: 50px;
}

.supervision {
  border: 5px solid #32bd50;
  border-radius: 1rem;
  padding: 0.4rem;
}

.theme--light.v-tabs-items {
  background-color: transparent;
}

.v-tab.v-tab--active {
  color: white !important;
  font-size: 0.8rem;
}
</style>
