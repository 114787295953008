<template>
  <div>
    <v-dialog v-model="dialog" max-width="900">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex">
          <v-btn class="mx-2 transparent" fab small v-bind="attrs" v-on="on">
            <v-icon> mdi-eye-outline </v-icon>
          </v-btn>
        </div>
      </template>
      <v-sheet
        class="gradient-sheet align-center justify-center text-center"
        height="100%"
      >
        <v-row class="fill-height">
          <v-col cols="10" md="6" class="d-flex">
            <div>
              <img
                v-if="user.avatar"
                :src="user.avatar"
                :alt="user.name"
                width="400px"
                height="400px"
              />
              <v-icon
                v-else
                color="white"
                size="400px"
                class="mdi mdi-account"
              ></v-icon>
              <div class="info-card-person">
                <p class="text-h3">
                  {{ user.name }},
                  {{ user.year }}
                  Anos - {{ user?.Formacao || 'Não informado' }}
                </p>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="6" class="text-center">
            <h3 class="text-h3">Evolução técnica</h3>
            <v-row cols="4">
              <v-col cols="12" class="">
                <div class="d-flex justify-end align-center">
                  <div class="text-h4">Evolução técnica:</div>
                  <v-rating
                    readonly
                    background-color="orange lighten-3"
                    color="orange"
                    half-increments
                    hover
                    length="5"
                    size="25"
                    :value="user.avaliation.evolucao_tecnica"
                  >
                  </v-rating>
                  <div class="align-self-start">
                    <sub>{{ user.avaliation.evolucao_tecnica }}</sub>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="">
                <div class="d-flex justify-end align-center">
                  <div class="text-h4">Planejamento e produção volumoso:</div>
                  <v-rating
                    readonly
                    background-color="orange lighten-3"
                    color="orange"
                    half-increments
                    hover
                    length="5"
                    size="25"
                    :value="user.avaliation.planejamento_producao"
                  >
                  </v-rating>
                  <div class="align-self-start">
                    <sub>{{ user.avaliation.planejamento_producao }}</sub>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="">
                <div class="d-flex justify-end align-center">
                  <div class="text-h4">Nutrição:</div>
                  <v-rating
                    readonly
                    background-color="orange lighten-3"
                    color="orange"
                    half-increments
                    hover
                    length="5"
                    size="25"
                    :value="user.avaliation.nutricao"
                  >
                  </v-rating>
                  <div class="align-self-start">
                    <sub>{{ user.avaliation.nutricao }}</sub>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="">
                <div class="d-flex justify-end align-center">
                  <div class="text-h4">Qualidade do leite:</div>
                  <v-rating
                    readonly
                    background-color="orange lighten-3"
                    color="orange"
                    half-increments
                    hover
                    length="5"
                    size="25"
                    :value="user.avaliation.qualidade_do_leite"
                  >
                  </v-rating>
                  <div class="align-self-start">
                    <sub>{{ user.avaliation.qualidade_do_leite }}</sub>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="">
                <div class="d-flex justify-end align-center">
                  <div class="text-h4">Ireprodução:</div>
                  <v-rating
                    readonly
                    background-color="orange lighten-3"
                    color="orange"
                    half-increments
                    hover
                    length="5"
                    size="25"
                    :value="user.avaliation.ireproducao"
                  >
                  </v-rating>
                  <div class="align-self-start">
                    <sub>{{ user.avaliation.ireproducao }}</sub>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="">
                <div class="d-flex justify-end align-center">
                  <div class="text-h4">Gestão econômica e financeira:</div>
                  <v-rating
                    readonly
                    background-color="orange lighten-3"
                    color="orange"
                    half-increments
                    hover
                    length="5"
                    size="25"
                    :value="user.avaliation.gestao_financeira"
                  >
                  </v-rating>
                  <div class="align-self-start">
                    <sub>{{ user.avaliation.gestao_financeira }}</sub>
                  </div>
                </div>
              </v-col>

              <v-col cols="12">
                <div class="supervision">
                  <p class="text-h3">
                    Número de supervisões recebidas:
                    <strong>
                      {{ user.avaliations.length || 0 }}
                    </strong>
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AvaliationTechnique',

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style scoped>
.gradient-sheet {
  background: linear-gradient(to bottom right, #0d133d, #243189);
  padding: 20px;
  border-radius: 8px;
}
.info-card-person {
  border: 5px solid #32bd50;
  border-radius: 1rem;
  padding: 0.4rem;
  max-width: 300px;
  bottom: 15px;
  margin-left: 50px;
}
.supervision {
  border: 5px solid #32bd50;
  border-radius: 1rem;
  padding: 0.4rem;
}
</style>
