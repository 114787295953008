<template>
  <div>
    <v-dialog v-model="dialog" max-width="900">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex">
          <v-btn class="mx-2 transparent" fab small v-bind="attrs" v-on="on">
            <v-icon> mdi-eye-outline </v-icon>
          </v-btn>
        </div>
      </template>
      <v-sheet
        class="gradient-sheet align-center justify-center text-center"
        height="100%"
      >
        <v-row class="fill-height">
          <v-col cols="10" md="6" class="d-flex">
            <div>
              <img
                v-if="user.avatar"
                :src="user.avatar"
                :alt="user.name"
                width="400px"
                height="400px"
              />
              <v-icon
                v-else
                color="white"
                size="400px"
                class="mdi mdi-account"
              ></v-icon>
              <div class="info-card-person">
                <p class="text-h3">
                  {{ user.name }},
                  {{ user.year }}
                  Anos - {{ user?.Formacao || 'Não informado' }}
                </p>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="6" class="align-start align-self-start">
            <h3 class="text-h3">Dados cadastrais</h3>
            <v-row cols="4">
              <v-col cols="12" class="">
                <div class="d-flex">
                  <div class="text-h4">Nome:</div>
                  <div class="text-h4 ml-2 font-weight-bold">
                    {{ user.name }}
                  </div>
                </div>
              </v-col>

              <v-col cols="12" class="">
                <div class="d-flex">
                  <div class="text-h4">Data de nascimento:</div>
                  <div class="text-h4 ml-2 font-weight-bold">
                    {{
                      user.pessoa?.pessoafisica?.DataNascimento
                        ? moment(
                            user.pessoa?.pessoafisica?.DataNascimento,
                          ).format('DD/MM/YYYY')
                        : 'Não informado'
                    }}
                  </div>
                </div>
              </v-col>

              <v-col cols="12" class="">
                <div class="d-flex">
                  <div class="text-h4">Formação:</div>
                  <div class="text-h4 ml-2 font-weight-bold">
                    {{ user.Formacao ? user.Formacao : 'Não informado' }}
                  </div>
                </div>
              </v-col>

              <v-col cols="12" class="">
                <div class="d-flex">
                  <div class="text-h4">CPF:</div>
                  <div class="text-h4 ml-2 font-weight-bold">
                    {{
                      user.pessoa.pessoafisica.Cpf
                        ? user.pessoa.pessoafisica.Cpf
                        : 'Não informado'
                    }}
                  </div>
                </div>
              </v-col>

              <v-col cols="12" class="">
                <div class="d-flex">
                  <div class="text-h4">Celular:</div>
                  <div class="text-h4 ml-2 font-weight-bold">
                    {{
                      user.pessoa.TelCel ? formatTel(user.pessoa.TelCel) : ''
                    }}
                    {{
                      user.pessoa.TelRes
                        ? ', ' + formatTel(user.pessoa.TelRes)
                        : ''
                    }}
                    {{
                      user.pessoa.TelRec
                        ? ', ' + formatTel(user.pessoa.TelRec)
                        : ''
                    }}
                  </div>
                </div>
              </v-col>

              <v-col cols="12" class="">
                <div class="d-flex">
                  <div class="text-h4">email:</div>
                  <div class="text-h4 ml-2 font-weight-bold">
                    {{ user.user.email }}
                  </div>
                </div>
              </v-col>

              <v-col cols="12" class="">
                <div class="d-flex">
                  <div class="text-h4">Endereço:</div>
                  <div class="text-h4 ml-1 font-weight-bold">
                    {{ formatEndereco(user.pessoa?.endereco) }}
                  </div>
                </div>
              </v-col>

              <v-col cols="12" class="">
                <div class="d-flex">
                  <div class="text-h4">Data de entrada na Milkplan:</div>
                  <div class="text-h4 ml-2 font-weight-bold">
                    {{
                      user.user.created_at
                        ? moment(user.user.created_at).format('DD/MM/YYYY')
                        : ''
                    }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'RegistrationData',

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
    }
  },

  methods: {
    formatTel(tel) {
      if (tel) {
        return tel.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3')
      }
    },

    formatCep(cep) {
      if (cep) {
        return cep.replace(/(\d{5})(\d{3})/, '$1-$2')
      }
    },

    formatEndereco(endereco) {
      if (!endereco) return 'Não informado'

      let result = ''

      const city = `${endereco?.cidade.Nome} - ${endereco?.cidade.estado.Sigla}`

      if (city) {
        result += city
      }

      if (endereco.Logradouro) {
        result += ', ' + endereco.Logradouro
      }

      if (endereco.Numero) {
        result += ', n°' + endereco.Numero
      }

      if (endereco.Complemento) {
        result += ', ' + endereco.Complemento
      }

      if (endereco.Bairro) {
        result += ', ' + endereco.Bairro
      }

      if (endereco.Cep) {
        result += ', ' + this.formatCep(endereco.Cep)
      }

      return result
    },
  },
}
</script>

<style scoped>
.gradient-sheet {
  background: linear-gradient(to bottom right, #0d133d, #243189);
  padding: 20px;
  border-radius: 8px;
}
.info-card-person {
  border: 5px solid #32bd50;
  border-radius: 1rem;
  padding: 0.4rem;
  max-width: 300px;
  /* position: absolute; */
  bottom: 15px;
  margin-left: 50px;
}

.supervision {
  border: 5px solid #32bd50;
  border-radius: 1rem;
  padding: 0.4rem;
}
</style>
