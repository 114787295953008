var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(!_vm.loading.loading)?_c('v-col',{attrs:{"cols":"12"}},[_c('FilterDialog',{attrs:{"consultores":_vm.consultores},on:{"filter":_vm.filter}})],1):_vm._e()],1),(_vm.loading.loading)?_c('base-loading'):(_vm.slides.length)?_c('v-carousel',{attrs:{"id":"carousel","cycle":false,"width":"100%","height":_vm.resize ? '100%' : '800px',"hide-delimiter-background":"","show-arrows-on-hover":"","continuous":false}},_vm._l((_vm.slides),function(slide,i){return _c('v-carousel-item',{key:i,attrs:{"fade":""}},[(slide)?_c('v-sheet',{key:slide.CodConsultorTecnico,ref:"carousel",refInFor:true,staticClass:"gradient-sheet align-center justify-center text-center",attrs:{"height":"100%"}},[_c('h2',{staticClass:"text-h2 font-weight-medium user-name"},[_vm._v(" "+_vm._s(slide.name)+" ")]),_c('v-row',{staticClass:"fill-height align-center"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"content-consultant"},[_c('div',[(slide.avatar)?_c('img',{staticClass:"image-consultant",attrs:{"src":slide.avatar,"alt":slide.name}}):_c('v-icon',{staticClass:"mdi mdi-account",attrs:{"color":"white","size":"350px"}})],1),_c('div',{staticClass:"info-card text-center"},[_c('p',{staticClass:"text-h3"},[_vm._v(" "+_vm._s(slide.name)+", "+_vm._s(slide.year)+" Anos - "+_vm._s(slide?.Formacao || 'Não informado')+" ")])])])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"7"}},[_c('h3',{staticClass:"text-h3"},[_vm._v("Informações gerais")]),_c('v-row',{attrs:{"cols":"4"}},[_c('v-col',{staticClass:"d-inline-flex",attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-h4"},[_vm._v("Dados cadastrais:")])])]),_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{
                      path: `/${_vm.$user.get().role}/consultor/form`,
                      query: { id: slide.CodConsultorTecnico },
                    },"target":"_blank"}},[_c('v-btn',{staticClass:"mx-2 transparent",attrs:{"fab":"","small":""}},[_c('v-icon',[_vm._v(" mdi-circle-edit-outline ")])],1)],1),_c('RegistrationData',{attrs:{"user":slide}})],1)]),_c('v-col',{staticClass:"d-inline-flex",attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-h4"},[_vm._v("Base/Reagião de atuação:")]),_c('div',{staticClass:"text-h4 font-weight-bold ml-1"},[_vm._v(" "+_vm._s(`${ slide.pessoa.endereco?.cidade?.Nome ?? 'Não informado' }, ${ slide.pessoa.endereco?.cidade?.estado.Sigla ?? 'Não informado' }` || 'Não disponível ')+" ")])])]),_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{attrs:{"to":{
                      path: `/${
                        _vm.$user.get().role
                      }/relatorios/farmDistribution`,
                      query: { userId: slide.CodConsultorTecnico },
                    },"target":"_blank"}},[_c('v-btn',{staticClass:"mx-2 transparent",attrs:{"fab":"","small":""}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)],1)],1)]),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-h4"},[_vm._v("Empresas vinculadas:")]),_c('div',{staticClass:"text-h4 font-weight-bold ml-1"},[_vm._v(" "+_vm._s(slide.entreprise)+" ")])])]),_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('LinkedCompanies',{attrs:{"user":slide,"agroindustry-logos":_vm.agrindustriaLogos}})],1)]),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-h4"},[_vm._v(" Visitas realizadas/Previstas último mês: ")]),_c('div',{staticClass:"text-h4 font-weight-bold ml-1"},[_vm._v(" "+_vm._s(slide.monthTotalVisits + '/' + slide.monthRealizedVisits)+" ")])])]),_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{
                      path: `/${_vm.$user.get().role}/schedules`,
                      query: { consultor: slide.CodConsultorTecnico },
                    },"target":"_blank"}},[_c('v-btn',{staticClass:"mx-2 transparent",attrs:{"fab":"","small":""}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)],1)],1)]),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-h4"},[_vm._v(" Custo acumulado por visita/Custo por visita mensal: ")]),_c('div',{staticClass:"text-h4 font-weight-bold ml-1"},[_vm._v(" R$ "+_vm._s(_vm.currencyFormatter(slide.monthTotalExpenses))+"/"+_vm._s(_vm.currencyFormatter(slide?.meta?.meta, 'R$'))+" ")])])]),_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{
                      path: `/${_vm.$user.get().role}/financeiros`,
                      query: { userId: slide.CodConsultorTecnico, tab: 1 },
                    },"target":"_blank"}},[_c('v-btn',{staticClass:"mx-2 transparent",attrs:{"fab":"","small":""}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)],1)],1)]),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-h4"},[_vm._v("Desempenho geral:")]),_c('v-rating',{attrs:{"readonly":"","background-color":"orange lighten-3","color":"orange","half-increments":"","hover":"","length":"5","size":"25","value":slide?.avaliation?.geral ?? 0}})],1)]),(slide.avaliations)?_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('AvaliationGeneral',{attrs:{"user":slide}})],1)]):_vm._e(),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-h4"},[_vm._v("Evolução técnica:")]),_c('v-rating',{attrs:{"readonly":"","background-color":"orange lighten-3","color":"orange","half-increments":"","hover":"","length":"5","size":"25","value":slide?.avaliation?.evolucao_tecnica ?? 0}})],1)]),(slide.avaliations)?_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('AvaliationTechnique',{attrs:{"user":slide}})],1)]):_vm._e()],1)],1)],1),(slide)?_c('v-btn',{staticClass:"fullscreen",attrs:{"color":"gray","icon":""},on:{"click":_vm.openFullscreen}},[_c('v-icon',[_vm._v(_vm._s(_vm.resize ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'))])],1):_vm._e()],1):_vm._e()],1)}),1):(!_vm.slides.length && !_vm.loading.loading)?_c('v-row',{staticClass:"not-found-item",attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"size":"64"}},[_vm._v("mdi-alert-circle-outline")]),_c('div',{staticClass:"headline"},[_vm._v(" Selecione um consultor para visualizar o dashboard ")]),_c('div',{staticClass:"caption"},[_vm._v(" Selecione um consultor valido para visualizar o dashboard ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }